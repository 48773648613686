
import Naja from 'naja';

/**
 * Plugin pro jQuery pro indikaci ajaxových dotazů.
 */
class AjaxIndicator {

	static PLUGIN = 'indicator';

	static DATA = `data-${AjaxIndicator.PLUGIN}`;

	static PROGRESS_CLASS = 'ajax-progress';

	static event = 'nette-naja-ajax';

	/**
	 * @type {HTMLElement}
	 */
	element = null;

	/**
	 * @type {NodeList|Array}
	 */
	indicators = null;

	/**
	 * @type {NodeList}
	 */
	counters = null;

	/**
	 * @type {number}
	 */
	count = 0;

	constructor(element) {
		this.element = element;
		element.dataset.ajaxRequests = 0;
		const selector = element.dataset[AjaxIndicator.PLUGIN];
		const counterSelector = element.dataset[`${AjaxIndicator.PLUGIN}Counter`];


		if (selector) {
			this.indicators =document.querySelectorAll(selector);
		} else {
			this.indicators = [element];
		}

		//this.indicator = selector ? document.querySelector(selector) : element;
		if (counterSelector) {
			this.counters = document.querySelectorAll(counterSelector);
		}
		element.addEventListener(AjaxIndicator.event,this.indicate.bind(this), true);
		this.updateCounter();
	}

	/**
	 * @param {Event} event
	 */
	indicate(event) {
		const { promise, xhr } = event.detail;
		this.increase();
		xhr.element.addEventListener('abort', this.decrease.bind(this));
		promise.then(this.decrease.bind(this), this.decrease.bind(this));
	}

	/**
	 */
	increase() {
		this.count++;
		this.indicators.forEach( el => el.classList.add(AjaxIndicator.PROGRESS_CLASS));
		this.updateCounter();
	}

	decrease() {
		this.count--;
		if (!this.count) {
			this.indicators.forEach( el => el.classList.remove(AjaxIndicator.PROGRESS_CLASS));
		}
		this.updateCounter();
	}

	updateCounter() {
		this.element.dataset.ajaxRequests = this.count;
		if (this.counters && this.counters.length) {
			this.counter.forEach(e=>e.innerHTML = this.count);
		}
	}

}

/**
 * Spouštění událostí 'nette-ajax' při spuštění ajaxového dotazu
 * Událost má parametr s vlastnostmi {Promise} promise, {XMLHttpRequest} xhr, {HTMLElement} element
 * @property {Naja} naja
 */
class IndicatorExtension {

	constructor(naja) {
		this.naja = naja;
		naja.addEventListener('load', this.load.bind(this));
		naja.addEventListener('start', this.start.bind(this));
		naja.addEventListener('before', this.before.bind(this));
		//naja.addEventListener('after', this.after.bind(this));
		naja.addEventListener('interaction', this.interaction.bind(this));
	}

	load() {
		document.querySelectorAll(`[${AjaxIndicator.DATA}]:not([${AjaxIndicator.DATA}=off])`)
			.forEach((el)=> el.indicator || (el.indicator = new AjaxIndicator(el)));
	}

	/**
	 * @param {Promise} request
	 * @param {XMLHttpRequest} xhr
	 */
	start({request, xhr}) {
		if (xhr.element) {
			xhr.element.dispatchEvent(new CustomEvent(AjaxIndicator.event, {
				detail: {promise: request, xhr:	 xhr}
			}));
		}
	}

	/**
	 * @param {XMLHttpRequest} xhr
	 * @param {Object} options
	 */
	before({xhr, options}) {
		if (options && options.element && options.indicator === true) {
			xhr.element = options.element;
		}
	}



	/**
	 * @param {Element} element
	 * @param {Object} options
	 */
	interaction({element, options}) {
		options.indicator = element.getAttribute(AjaxIndicator.DATA) !== 'off';
		options.element = element;
	}

}

//AjaxIndicator.install();
Naja.registerExtension(IndicatorExtension);

export {AjaxIndicator, IndicatorExtension};
