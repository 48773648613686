import Naja from 'naja';
import Nette from 'nette-forms';
import Status from './forms.status';
import Cache from 'utils/Cache';
import scrollIt from './scrollIt';
//import './closest.polyfill';
import Button from 'utils/vanilla.button.js';

/**
 * @return {Set|Set<string>}
 */
HTMLElement.prototype.getMessages = function () {
	return this.messages || (this.messages = new Set());
};

/**
 * @return {Set|Set<number>}
 */
HTMLFormElement.prototype.getTimeouts = function () {
	return this.timeouts || (this.timeouts = new Set());
};

const equals = (a, b) => {
	if (a === b) {
		return true;
	}
	if (a === null || b === null) {
		return false;
	}
	if (a === undefined || b === undefined) {
		return false;
	}
	if (a.length !== b.length) {
		return false;
	}
	for (let i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) {
			return false;
		}
	}
	return true;
};
Nette.toggle = (id, visible) => {
	const el = document.getElementById(id);
	if (el) {
		el.classList.add('form-toggle');
		if (visible) {
			el.classList.remove('toggled');
		} else {
			el.style.height = el.clientHeight+'px';
			el.classList.add('toggled');
		}
	}
};
/**
 *
 * @param {HTMLInputElement} e
 */
Nette.getContainer = (e) => e.closest('.form-control:not(input):not(select),.form-group,[class*=col]');

export class FormsExtension {

	constructor(naja) {
		naja.formsHandler.netteForms = Nette;
		naja.addEventListener('interaction', this.interaction.bind(this));
		naja.addEventListener('before', this.before.bind(this));
		naja.addEventListener('complete', this.complete.bind(this));
	}

	/**
	 * Prevence před dvojím odesláním formuláře
	 * @param {Event} event
	 */
	interaction(event) {
		const {element, originalEvent, options} = event;
		const form = element.tagName === 'form' ? element : element.form;
		options.formElement = form;
	}

	/**
	 *
	 * @param {Element} element
	 * @param {Object} options
	 */
	before({options}) {
		const form = options.formElement;
		if (form && form['nette-submittedBy']) {
			options.button = new Button(form['nette-submittedBy']);
			options.button.setState('loading');
		}
	}

	/**
	 *
	 * @param {Object} options
	 */
	complete({options}) {
		if (options.button) {
			options.button.setState('reset');
		}
	}
}


/**
 * Tools for extending functions
 */
class Functions {
	/**
	 *
	 * @param {object} obj
	 * @param {string} name name of function to be extended
	 * @param {function} callback
	 */
	static prepend(obj, name, callback) {
		const original = obj[name];
		obj[name] = (...args) => {
			if (callback.call(obj, ...args) === false) {
				return false;
			}
			return original.call(obj, ...args);
		};
	}

	/**
	 *
	 * @param {object} obj
	 * @param {string} name name of function to be extended
	 * @param {function} callback
	 */
	static append(obj, name, callback) {
		const original = obj[name];
		obj[name] = (...args) => {
			const returnValue = original.call(obj, ...args);
			return callback.call(obj, returnValue, ...args);
		};
	}
}


class AjaxValidator {
	static results = {
		true: Status.success,
		false: Status.error,
		null: Status.reset,
	};
	promise = null;
	lastValue = null;
	timeout = null;
	url = null;
	control = null;
	cache = [];

	constructor(elem, args) {
		this.element = elem;
		this.form = elem.form;
		//this.$element = $(elem);
		this.args = args;
		this.url = elem.form.action;
		this.control = elem.form.id.replace(/^frm-/, '');

	}

	static getInstance(elem, args) {
		if (elem.ajaxValidator) {
			return elem.ajaxValidator;
		}
		elem.ajaxValidator = new AjaxValidator(elem, args);
		return elem.ajaxValidator;
	}

	static createCallback() {
		return function (elem, args, value) {
			return AjaxValidator.getInstance(elem, args).validate(value);
		};
	}

	/**
	 *
	 * @param {string} value
	 * @param {Object} response
	 * @param {Boolean} isCached
	 */
	resolve(value, response, isCached) {
		if (response === null) {
			return null;
		}
		if (!isCached) {
			Cache.getInstance(this.element).add(value, response);
		}
		return response.result === undefined ? true : Boolean(response.result);
	}

	/**
	 *
	 * @param {HTMLInputElement} elem
	 * @param {Object} args
	 * @param {string} val
	 */
	validate(val) {
		this.lastValue = val;
		if (typeof this.element.isValid !== 'undefined' && (!val || !this.element.isValid)) {
			return null;
		}
		if (this.element.defaultValue === val) {
			return true;
		}
		const cached = Cache.getInstance(this.element).get(val);
		if (cached === null) {
			return null;
		}
		if (typeof cached !== 'undefined') {
			return this.resolve(val, cached, true);
		}
		Cache.getInstance(this.element).add(val, null);
		clearTimeout(this.timeout);
		Status.trigger([Status.start, Status.ajax], this.element);
		this.timeout = setTimeout(() => {
			this.sendRequest(val);
		}, 500);
		return null;
	}

	sendRequest(val) {
		const promise = this.promise = this.submitForm(this.form, {'unique': false, 'element': this.element});
		if (promise) {
			promise
				.then(this.then.bind(this, val))
				.catch(this.error.bind(this, val));
		}
	}

	submitForm(form, options = {}) {
		const method = form.method ? form.method.toUpperCase() : 'GET';
		const url = form.action || window.location.pathname + window.location.search;
		const data = new FormData(form);
		data.append('_do', `${this.control}-validate`);
		data.append('_validate', this.element.name);
		return Naja.makeRequest(method, url, data, options);
	}

	/**
	 * @param val
	 * @param {Error} errorMessage
	 */
	error(val, errorMessage) {
		console.error(errorMessage);
	}

	then(val, response) {
		// Status.trigger(AjaxValidator.results[this.resolve(val, response, false)], this.element);
		this.resolve(val, response, false);
		Nette.validateControl(this.element, undefined, document.activeElement === this.element);
		Status.trigger([Status.complete, Status.ajax], this.element);
	}
}

/**
 *
 * @param {HTMLInputElement} element
 * @return {string}
 */
Nette.getId = function (element) {
	if (!element.id) {
		let id = `${element.form.id}-${element.name.replace(/\[\]/, '')}`;
		if (['radio', 'checkbox'].indexOf(element.type) !== -1) {
			id += `-${element.value}`;
		}
		element.id = id;
	}
	return element.id;
};
/**
 * @param {HTMLInputElement} control
 * @param {string} message
 */
Nette.addError = (control, message) => {
	Nette.formErrors.push({control, message});
	control.getMessages().add(message);
};
/**
 *
 * @param {HTMLFormElement} form
 * @param errors
 * @param focus
 */
Nette.showFormErrors = (form, errors, focus = true) => {
	let focusElem, element, i;
	const {elements} = form;
	for (i = 0; i < elements.length; i++) {
		element = elements[i];
		if (Nette.showInputErrors(element) && focus && !focusElem) {
			focusElem = element;
		}
	}
	if (focusElem) {
		scrollIt(focusElem);
		//$(focusElem).scrollToElement();
		focusElem.focus();
	}
};
/**
 * @param {HTMLInputElement|HTMLButtonElement} control
 * @return {boolean}
 */
Nette.showInputErrors = (control) => {
	const messages = control.getMessages();
	if (messages.size) {
		const container = Nette.getContainer(control);
		let feedback = container.querySelector('ul.invalid-feedback');// Todo přidat konfigurovatelnost typu elementu feedbacku
		if (!feedback) {
			feedback = document.createElement('ul');
			feedback.classList.add('invalid-feedback');
			control.parentNode.appendChild(feedback);
		}
		feedback.innerHTML = null;
		for(let message of messages){
            const li = document.createElement("li");
            li.appendChild(document.createTextNode(message));
            feedback.appendChild(li);
		}
		return true;
	}
	return false;
};

export class AutoForm {
	static validationTimeout = 300;
	static autoSubmitTimeout = 1000;

	static submit(element, timeout, submitter) {
		const {form} = element;

		clearTimeout(form.dataset.lastTimeout);
		form.dataset.lastTimeout = setTimeout(() => {
			console.log(element, timeout, submitter, submitter.tagName);
			if (submitter.tagName.toLowerCase() === 'form') {

				submitter.submit();
			} else {
				submitter.click();
			}

		}, Number(timeout));
	}

	/**
	 *
	 * @param {HTMLInputElement} input
	 * @param {Event} e
	 */
	static validate(input, e) {
		const timeouts = input.form.getTimeouts();
		let timeout;
		clearTimeout(input.dataset.validationTimeout);
		timeouts.add(timeout = input.dataset.validationTimeout = setTimeout(() => {
			timeouts.delete(timeout);
			const checkOnly = e.type !== 'focusout';
			let timeout2;
			if (Nette.validateControl(input, undefined, checkOnly) === false && !checkOnly) {
				timeouts.add(timeout2 = setTimeout(() => {
					timeouts.delete(timeout2);
					Nette.showInputErrors(input);
				}, AutoForm.validationTimeout));
			}
		}, AutoForm.validationTimeout));
	}

	/**
	 *
	 * @param {Event|KeyboardEvent} e
	 */
	static handleSubmit(timeout, submitter, e) {
		if (!AutoForm.validateEvent(e)) {
			return true;
		}
		AutoForm.submit(e.target, timeout, submitter);
	}

	/**
	 *
	 * @param {Event|KeyboardEvent } e
	 */
	static handleValidate(e) {
		if (!AutoForm.validateEvent(e)) {
			return true;
		}
		AutoForm.validate(e.target, e);
	}

	static validateEvent(e) {
		return !(e.type === 'keyup' && !AutoForm.isKeyValid(e.keyCode || e.charCode));
	}

	static isKeyValid(keyCode) {
		return true;// keyCode >= 48 && keyCode <= 90 || keyCode >= 96 && keyCode <= 111 || keyCode >= 186 && keyCode <= 192 || keyCode >= 219 && keyCode <= 222;
	}

	/**
	 * @param {HTMLFormElement} form
	 */
	static initForm(form) {
		const {elements} = form,
			forceSubmit = form.classList.contains('autosubmit'),
			forceValidate = form.dataset.validation === 'live',
			submitter = elements.autosubmit && elements.autosubmit.type === 'submit' ? elements.autosubmit : form;
		for (let i = 0; i < form.elements.length; i++) {
			AutoForm.initAutoSubmit(form.elements[i], forceSubmit, submitter);
			AutoForm.initAutoValidate(form.elements[i], forceValidate);
		}
	}

	/**
	 * @param {HTMLInputElement} element
	 * @param {Boolean} force
	 */
	static initAutoSubmit(element, force, submitter) {
		if (typeof element.dataset.autosubmit === 'undefined' && !force) {
			return;
		}
		const triggers = typeof element.dataset.autosubmit === 'string' ? element.dataset.autosubmit.split(' ') : force ? ['change'] : [];
		const timeout = element.dataset.autosubmitTimeout || submitter.dataset.autosubmitTimeout || AutoForm.autoSubmitTimeout;
		triggers.forEach((trigger) => {
			element.addEventListener(trigger, AutoForm.handleSubmit.bind(this, timeout, submitter));
		});
	}

	/**
	 * @param {HTMLInputElement} element
	 * @param {Boolean} force
	 */
	static initAutoValidate(element, force) {
		if (element.dataset.validation !== 'live' && !force) {
			return;
		}
		['change', 'keyup', 'focusout'].forEach((trigger) => {
			element.addEventListener(trigger, AutoForm.handleValidate);
		});
	}

}

/**
 * Zobrazení chybových hlášek javascriptem
 */
Functions.prepend(Nette, 'initForm', (form) => {
	if (!form.noValidate) {
		AutoForm.initForm(form);
	}
});

/**
 * Prevence dvojího odeslání pro neajaxového formuláře
 * Pro deaktivaci tlačítka je nutné aby bylo specifikováno ve formuláři jako vlastnost nette-submittedBy.
 */
Functions.append(Nette, 'initForm', (prevValue, form) => {
	if (form.inicialized) {
		return prevValue;
	}
	form.inicialized = true;
	Nette.addEvent(form, 'submit', (e) => {
		if (!e.defaultPrevented) {
			if (form.submitted) {
				e.preventDefault();
				return false;
			}
			form.submitted = true;
			if (form['nette-submittedBy']) {
				(new Button(form['nette-submittedBy'])).setState('loading');
			}
		}
		return true;
	});
	return prevValue;
});
/*
 $(document).on('click', 'form [type=image],form [type=submit]', function () {
 this.form['nette-submittedBy'] = this;
 }).on('click change keydown', ':input', function () {
 Status.trigger(Status.reset, this);
 }).on('change', '.custom-file-input[id]', function (e) {
 "use strict";
 const $label = $('.custom-file-label[for=' + this.id + ']');
 if ($label.length) {
 const files = e.target.files;
 if (files.length) {
 const files_array = Object.values(e.target.files);
 const names = files_array.map(file=>file.name).join(', ');
 $label.data('original-text', $label.text()).text(names);
 } else if ($label.data('original-text')) {
 $label.text($label.data('original-text'));
 }
 }
 });
 */
Functions.append(Nette, 'validateRule', (prevValue, element, rule) => {
	element.isValid = element.isValid && prevValue;
	return prevValue;
});

// Události validace formuláře
Functions.prepend(Nette, 'validateControl', (element) => {
	element.isValid = true;
	if (element.messages) {
		element.messages.clear();
	}
	clearTimeout(element.dataset.validationTimeout);
	Status.trigger(Status.start, element);
});

Functions.append(Nette, 'validateControl', (prevValue, element) => {
	Status.trigger(AjaxValidator.results[prevValue], element);
	Status.trigger(Status.complete, element);
	return prevValue;
});
Functions.append(Nette, 'validateForm', (prevValue, sender) => {
	(sender.form || sender).classList.add('was-validated');
	return prevValue;
});

Naja.registerExtension(FormsExtension);
Nette.validators.AppCommonFormsValidators_ajax = AjaxValidator.createCallback();
Nette.validators.AppCommonFormsValidators_fill = (element, arg)=> {
	console.log(element, arg);
	return Boolean(element.value = arg)
};
