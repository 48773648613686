/**
 * BootstrapToolkit class
 */
class BootstrapToolkit {
	constructor() {
		this.currentBreakpoint = null;
		this.node = document.createElement('div');
		this.node.setAttribute('id', 'bs-breakpoint');
		document.body.appendChild(this.node);
		//window.addEventListener('resize', this.onResize.bind(this));
	}

	/*onResize() {
		this.currentBreakpoint = null;
	}*/

	/**
     * Return string representation of current breakpoint.
     * @returns {string} xs,sm,md,lg,xl ...
     */
	getCurrentBreakpoint() {
		/*if (this.currentBreakpoint === null) {
			this.currentBreakpoint = window.getComputedStyle(this.node, ':before').getPropertyValue('content').replace(/([\\'"])/g, '');
		}
		return this.currentBreakpoint;*/
		return  window.getComputedStyle(this.node, ':before').getPropertyValue('content').replace(/([\\'"])/g, '');
	}

	/**
     * Returns value for current breakpoint
     * @param {Object} breakpoints example {xs: 'ano',lg: 'ne'}
     * @param {*} _default
     * @returns {*}
     */
	getValue(breakpoints, _default) {
		if (typeof breakpoints !== 'object') {
			return _default;
		}
		const currentBreakpoint = this.getCurrentBreakpoint();
		let value;
		let breakpoint;
		for (breakpoint in breakpoints) {
			if (breakpoints.hasOwnProperty(breakpoint)) {
				value = breakpoints[breakpoint];
				if (currentBreakpoint === breakpoint) {
					break;
				}
			}
		}
		return value;
	}

	/**
     * @todo create version detection
     * @returns {number}
     */
	static detectVersion() {
		return 4;
	}
}

window.BootstrapToolkit = new BootstrapToolkit();
