import Naja from 'naja';
import Bootstrap from 'bootstrap.native/dist/bootstrap-native-v4';

const
	modal = 'modal',
	show = 'show',
	before = 'before',
	after = 'after',
	hide = 'hide',
	snippet = 'snippet',
	namespace = 'nette',
	snippetBefore = `${snippet}-${before}`,
	snippetAfter = `${snippet}-${after}`,
	modalShow = `.${modal}.${show}`;

/**
 * Základní rozšíření ajaxové knihovny Naja
 * 1. Zobrazuje modální okna bootstrapu po načtení stránky nebo překreslení stránky
 * 2. Přidávání ke snipetům třídy before-update před překreslením nebo after-update po překreslení. Využitelné například pro přidání animací.
 * 3. Posílá události before-update.nette před překreslením nebo after-update.nette po překreslení.
 */
export default class AjaxExtension {

	contentModal = null;
	Modal = null;
	/**
	 *
	 * @type {Naja}
	 */
	naja = null;
    /**
	 * @type {string}
     */
	lang;

	init() {
		[this.lang] = document.documentElement.lang.split('_');
		this.contentModal = document.getElementById('content-modal');
	}

	constructor(naja) {
		this.naja = naja;
		naja.addEventListener('init', this.init.bind(this));
		naja.snippetHandler.addEventListener('beforeUpdate', this.snippetBefore.bind(this));
		naja.snippetHandler.addEventListener('afterUpdate', this.snippetAfter.bind(this));
		naja.addEventListener('success', this.success.bind(this));
		naja.addEventListener('error', this.error.bind(this));
		naja.addEventListener('load', this.loadModals.bind(this));
		naja.addEventListener('interaction', this.interaction.bind(this));
	}

	success({response}) {
		if (response.scrollTop) {
			window.scrollTo(0, 0);
		}
	}

	interaction(event) {
		const {element, options} = event;

		if (element.dataset.post) {
			this.naja.makeRequest('POST', element.dataset.url || element.href, JSON.parse(element.dataset.post), options);
			event.preventDefault();
			return false;
		}
	}

	loadModals() {
		document.querySelectorAll(modalShow).forEach(e=> (new Bootstrap.Modal(e)).show());
		document.querySelectorAll('[data-modal]').forEach(element=> {
			element.addEventListener('click', (event)=> {
				this.showContentModal(element.dataset.modal, element.title);
				event.preventDefault();
				return false;
			});
		});
	}

	/**
	 *
	 * @param error
	 * @param {XMLHttpRequest} xhr
	 * @param {Object} response
	 * @param options
	 */
	error({xhr, response, options}) {
		if (xhr.status === 500 || (typeof response === 'string' && response.startsWith('<!DOCTYPE html>'))) {
			if (response) {
				this.showContentModal(response);
			} else {
				var xhr2 = new XMLHttpRequest();
                xhr2.onreadystatechange = ()=> {
					this.showContentModal(xhr2.responseText);
				};
                xhr2.open("GET", '/500.' + this.lang + '.html', true);
                xhr2.send();
			}
		} else {
			this.naja.fireEvent('success', {xhr, response, options});
		}
	}

	showContentModal(content, title = null) {
		const modal = this.contentModal;
		const modalBody = modal.querySelector('.modal-body');
		const modalTitle = modal.querySelector('.modal-title');
		modalBody.innerHTML = content;
		if (modalTitle) {
			if (title !== null) {
				modalTitle.innerHTML = title;
			} else {
				const _title = modalBody.querySelector('title');
				if (_title) {
					modalTitle.innerHTML = _title.innerText;
				}
			}
		}
		(new Bootstrap.Modal(this.contentModal)).show();
	}

	/**
	 *
	 * @param {Element} snippet
	 */
	snippetBefore({snippet}) {
		if (this.Modal) {
			document.querySelectorAll(modalShow).forEach(e=> (new Modal(e)).hide());
		}
		const list = snippet.classList;
		list.add(snippetBefore);
		list.remove(snippetAfter);
		snippet.dispatchEvent(new Event(`${snippetBefore}.${namespace}`));
	}

	/**
	 *
	 * @param {Element} snippet
	 */
	snippetAfter({snippet}) {
		const list = snippet.classList;
		list.add(snippetAfter);
		list.remove(snippetBefore);
		snippet.dispatchEvent(new Event(`${snippetAfter}.${namespace}`));
	}
}

Naja.registerExtension(AjaxExtension);
