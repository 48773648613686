/**
 * Přidávání tříd bootstrapu při validaci
 */
export default class Status {
	static classes = {
		container: {
			success: 'has-success',
			error: 'has-danger',
			warning: 'has-warning',
			info: 'has-info',
			validated: 'was-validated',
			// start: 'has-progress',
			// complete: 'has-progress',
			'start-ajax': 'has-progress',
		},
		input: {
			success: 'is-valid',
			error: 'is-invalid',
			warning: 'has-warning',
			info: 'has-info',
			// start: 'has-progress',
			'start-ajax': 'has-progress',
		},
	};
	static namespace = 'nette.validation';
	static container = '.form-group';
	static error = 'error';
	static success = 'success';
	static reset = 'reset';
	static complete = 'complete';
	static start = 'start';
	static ajax = 'ajax';
	static on = {
		'start-ajax': {
			add: ['start-ajax'],
		},
		'complete-ajax': {
			remove: ['start-ajax'],
		},
		start: {
			add: ['start'],
		},
		complete: {
			remove: ['start'],
		},
		success: {
			remove: ['error'],
			add: ['success'],
		},
		error: {
			remove: ['success'],
			add: ['error'],
		},
		reset: {
			remove: ['success', 'error'],
		},
	};

	static getInputClasses() {
		return Status.classes.inputClasses || (Status.classes.inputClasses = Object.values(Status.classes.input));
	}

	static getContainerClasses() {
		return Status.classes.containerClasses || (Status.classes.containerClasses = Object.values(Status.classes.container));
	}

	static event(status) {
		if (Array.isArray(status)) {
			status = status.join('-');
		}
		return `validation-${status}.${Status.namespace}`;
	}

	static setStatus(element, status) {
		Status.clearStatus(element);
		Status.addStatus(element, status);
	}

	static addStatus(element, status) {
		Status.addClass(element, Status.classes.input[status]);
		Status.addClass(Status.getContainer(element), Status.classes.container[status]);
		// Status.addClass(Status.getContainer(element), Status.classes.container.validated);
	}

	static removeStatus(element, status) {
		Status.removeClass(element, Status.classes.input[status]);
		Status.removeClass(Status.getContainer(element), Status.classes.container[status]);
	}

	static addClass(element, cls) {
		if (element) {
			element.classList.add(cls);
		}
	}

	static removeClass(element, cls) {
		if (element) {
			element.classList.remove(cls);
		}
	}

	/**
	 * @param {Element} element
	 * @param {array} classes
	 */
	static removeClasses(element, classes) {
		if (element) {
			classes.forEach((cls) => {
				element.classList.remove(cls);
			});
		}
	}

	static clearStatus(element) {
		Status.removeClasses(element, Status.getInputClasses());
		Status.removeClasses(Status.getContainer(element), Status.getContainerClasses());
	}

	static apply(action, status, element) {
		element = element.form.elements[element.name];
		element = element instanceof NodeList ? element : element instanceof HTMLCollection ? Array.from(element) : [element];
		element.forEach((el) => Status[`${action}Status`](el, status));
	}

	/**
	 * Returns jQuery plugin for specified action
	 * @param action
	 * @returns {Function}
	 */
	static plugin(action) {
		return function (status) {
			$(this).each(function () {
				Status.apply(action, status, this);
			});
		};
	}

	/**
	 * Register jQuery plugins for all actions
	 * Add action listeners for events to document
	 */
	static install() {
		/*const $document = $(document);
		$.each(Status.on,(on, actions) => {
			$document.on(Status.event(on), 'form :input', (e) => {
				$.each(actions, (action, operations) => {
					$.each(operations, (i, operation) => {
						Status.apply(action, operation, e.target);
					});
				});
			});
		});
		['remove', 'add', 'set'].forEach((action) => {
			$.fn[`${action}Status`] = Status.plugin(action);
		});*/
	}

	/**
	 * @param {Element} element
	 */
	static getContainer(element) {
		return element.closest(Status.container);
	}

	/**
	 *
	 * @param {string} status
	 * @param {HTMLInputElement|NodeList} element
	 */
	static trigger(status, element) {
		element.dispatchEvent(new CustomEvent(Status.event(status)));
	}
}
Status.install();
