
export default class InViewPort {
	static PLUGIN_NAME = 'inViewport';
	static SELECTOR = '[data-in-viewport]';
	static TRIGGERED_CLASS = 'in-viewport';
	static SINGLE_MODE = 'single';
	static CONTINUOUS_MODE = 'continuous';
	static VALIDATE_BOTTOM = 'bottom';
	static VALIDATE_TOP = 'top';
	static VALIDATE_BOTH = 'both';
	static EVENT_IN = 'in.viewport';
	static EVENT_OUT = 'out.viewport';
	static MODES = [InViewPort.SINGLE_MODE, InViewPort.CONTINUOUS_MODE];
	static VALIDATIONS = [InViewPort.VALIDATE_BOTTOM, InViewPort.VALIDATE_TOP, InViewPort.VALIDATE_BOTH];
	static DEFAULTS = {
		top: 0.8,
		bottom: 0.8,
		mode: InViewPort.SINGLE_MODE,
		validate: InViewPort.VALIDATE_BOTH,
		callbackIn(element) {
			element.dispatchEvent(new CustomEvent(InViewPort.EVENT_IN));
			element.classList.add(InViewPort.TRIGGERED_CLASS);
			return true;
		},
		callbackOut(element) {
			element.dispatchEvent(new CustomEvent(InViewPort.EVENT_OUT));
			element.classList.remove(InViewPort.TRIGGERED_CLASS);
			return true;
		},
	};

	triggered = false;

	constructor(element, options) {
		this.element = element;
		let dataOptions = element.dataset[InViewPort.PLUGIN_NAME] ? JSON.parse(element.dataset[InViewPort.PLUGIN_NAME]) : {};
		this.options = Object.assign({},InViewPort.DEFAULTS,dataOptions,options);

		this.mode = this.options.mode;
		this.checkTop = [InViewPort.VALIDATE_TOP, InViewPort.VALIDATE_BOTH].indexOf(this.options.validate) !== -1;
		this.checkBottom = [InViewPort.VALIDATE_BOTTOM, InViewPort.VALIDATE_BOTH].indexOf(this.options.validate) !== -1;
		if (InViewPort.MODES.indexOf(this.mode) < 0) {
			throw new Error(`Unsupported mode ${this.mode}`);
		}
		if (InViewPort.VALIDATIONS.indexOf(this.options.validate) < 0) {
			throw new Error(`Unsupported validation ${this.options.validate}`);
		}
		this.watchFunction = this.watcher.bind(this);
		window.addEventListener('resize',this.watchFunction);
		window.addEventListener('scroll',this.watchFunction);
		element.addEventListener('destroy',this.destroy.bind(this));
		this.watcher();
	}

	watcher() {
		if (this.validate()) {
			return this.triggerIn();
		} else if (this.triggered) {
			return this.triggerOut();
		}
		return false;
	}

	validate() {
		/*if (!this.element.isVisible()) {
			return false;
		}*/
		const rect = this.element.getBoundingClientRect();
		const offsetTop = document.documentElement.scrollTop + rect.top;
		const bottom = !this.checkBottom || ((window.pageYOffset  + window.innerHeight * (1 - this.options.bottom)) < (offsetTop + rect.height));
		const top = !this.checkTop || (offsetTop < window.pageYOffset  +  window.innerHeight * this.options.top);
		return bottom && top;
	}

	triggerIn() {
		this.triggered = true;
		if (this.options.callbackIn(this.element)
			&& this.mode === InViewPort.SINGLE_MODE) {
			this.destroy();
		}
	}

	triggerOut() {
		this.triggered = false;
		if (this.options.callbackOut(this.element)
			&& this.mode === InViewPort.SINGLE_MODE) {
			this.destroy();
		}
	}

	destroy() {
		window.removeEventListener('resize',this.watchFunction);
		window.removeEventListener('scroll',this.watchFunction);
		delete this.element[InViewPort.PLUGIN_NAME];
	}

	static onLoad() {
		for(let node of document.querySelectorAll(`${InViewPort.SELECTOR}:not(.in-viewport-initialized):not(.${InViewPort.TRIGGERED_CLASS})`) ){
			if(!node[InViewPort.PLUGIN_NAME]){
				node.classList.add('in-viewport-initialized');
				node[InViewPort.PLUGIN_NAME] = new InViewPort(node);
			}
		}
	}
}
