import Naja from 'naja';
import BaseExtension from 'utils/BaseExtension';
//import ExtensionLoader from 'utils/ExtensionLoader';
import Swiper from 'swiper';
import Rellax from 'rellax';
import InViewport from 'utils/css-animations/inViewport';
import scrollIt from '../../common/js/scrollIt';
import './destroy';
import GoogleMapsLoader from 'google-maps';
import ParallaxBackground from './parallax-vanilla';
import 'flickity';
GoogleMapsLoader.KEY = document.querySelector('[data-google-maps-api-key]').dataset.googleMapsApiKey;

const doc = document;


//import InViewPort from 'utils/inViewport/jquery.inViewport';
class ApplicationExtension extends BaseExtension {

    lang = null;
    navigation = null;

    /**
     * @type {MutationObserver}
     */
    observer = null;


    init() {
        // Start Rellax
        this.rellax = new Rellax('.rellax');
        this.navigation = doc.querySelector('[data-navigation]');
        [this.lang] = doc.documentElement.lang.split('_');
        ParallaxBackground.init();
    }

    initSwiper(e) {
        let activateAnimations = (swiper) => {
            for (let caption of swiper.slides[swiper.activeIndex].querySelectorAll('[data-caption-animate]')) {
                setTimeout(() => {
                    caption.classList.remove('not-animated');
                    caption.classList.add(caption.dataset.captionAnimate, 'animated');
                }, Number(caption.dataset.captionDelay));
            }
        };
        e.swiper || (e.swiper = new Swiper(e, Object.assign({
            on: {
                init() {
                    activateAnimations(this);
                },
                slideChange() {
                    this.slides.each((i, slide) => {
                        for (let caption of slide.querySelectorAll('[data-caption-animate]')) {
                            caption.classList.remove(caption.dataset.captionAnimate, 'animated');
                            caption.classList.add('not-animated');
                        }
                    });
                    activateAnimations(this);
                }
            }
        }, JSON.parse(e.dataset.swiper))));
    }


    load() {
        // Destroy and create again parallax with previous settings
        this.rellax.refresh();
        window.BSN.initCallback();
        InViewport.onLoad();
        ParallaxBackground.load();
        for (let e of doc.querySelectorAll('[data-google-map]')) {
            e.map || GoogleMapsLoader.load((google) => {
                e.map = new google.maps.Map(e, JSON.parse(e.getAttribute('data-google-map')));
                var marker = new google.maps.Marker({
                    position: {lat: 49.873983, lng: 14.799009},
                    map: e.map,
                    title: 'Marie Solařová kosmetické služby'
                });
            });
        }
        for (let e of doc.querySelectorAll('.swiper-container')) this.initSwiper(e);
        //for (let e of doc.querySelectorAll('[data-parallax]')) this.initParalax(e);
        for (let e of doc.querySelectorAll('[data-open]')) e.addEventListener('click', this.open.bind(this, e));
        for (let a of doc.querySelectorAll('[data-scroll-to]')) {
            a.removeAttribute('data-scroll-to');
            let hash = a.getAttribute('href'), target;
            if (hash && (target = doc.querySelector(hash))) {
                a.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (window.location.hash === hash) {
                        e.preventDefault();
                    }
                    scrollIt(Math.max(target.offsetTop - this.navigation.offsetHeight, 0));
                    let collapse = a.closest('.collapse');
                    if (collapse && collapse.classList.contains('show')) {
                        let trigger = document.querySelector('[data-toggle="collapse"][data-target="#' + collapse.id + '"]');
                        if (trigger.Collapse) {
                            trigger.Collapse.hide();
                        }
                    }
                });
            }
        }
        ;
    }

    open(element, event) {
        event.preventDefault();
        window.open(element.href, element.title, 'width=300,height=300,top=0,left=0');
        return false;
    }
}

const cookies = document.getElementById('cookies-ack');
if (cookies) {
    cookies.addEventListener('closed.bs.alert', () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        document.cookie = `cookiesAck=1; path=/; expires=${date}`;
    });
}

//const lazyLoadedExtensions = new Map();
//lazyLoadedExtensions.set('[data-provide=datepicker]:not(.flatpickr-input)', () => import('../../common/js/DatePickersExtension.js'));
//lazyLoadedExtensions.set('[data-mask]', () => import('../../common/js/MaskExtension.js'));
//lazyLoadedExtensions.set('select,[data-choices],.choices', () => import('../../common/js/ChoicesExtension.js'));

// -- Nette Ajax---------------------------------------------------------------------------------------
//InViewPort.install();
Naja.registerExtension(ApplicationExtension);
//Naja.registerExtension(ExtensionLoader, lazyLoadedExtensions, {Nette, scrollIt});
Naja.initialize();


